<template>
  <!-- Conversations Card -->

  <!-- / Conversations Card -->
  <div>
    <a-card
      :bordered="false"
      class="header-solid h-full"
      :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }"
      style="margin: 0px 0px 24px"
    >
      <template #title>
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12">
            <h6 class="font-semibold m-0">{{ title }}</h6>
            <p v-if="subTitle">
              共: <span class="text-primary">{{ subTitle }}人</span>
            </p>
          </a-col>
        </a-row>
      </template>
      <a-list
        class="conversations-list"
        item-layout="horizontal"
        :split="false"
        :data-source="data"
      >
        <a-list-item slot="renderItem" slot-scope="item">
          <a-button @click="matchLog(item.matchUrl)" slot="actions" type="link">
            比赛记录
          </a-button>
          <a-button
            @click="
              handle(
                'https://arena.5eplay.com/data/player/' + item.user_info.domain
              )
            "
            slot="actions"
            type="link"
          >
            战绩页
          </a-button>
          <a-list-item-meta :title="item.userName">
            <!-- :description="item.banSatatus" -->
            <template #description>
              <!-- result.indexOf(str) != -1 str in result -->
              <span
                v-if="
                  item.user_info.account_status === -4
                "
                style="color: red"
                >作弊封禁</span
              >
              <span
              v-else-if="
                  item.user_info.account_status === -6
                "
                style="color: orange"
                >违规行为封禁</span
              >
              <!-- <span
                v-else-if="
                  parseInt(item.user_info.login_banned_time) > Date.now() / 1000
                "
                style="color: orange"
                >违规冻结至 {{ formatUnix(item.user_info.login_banned_time) }}
              </span> -->
              <span v-else>未被封禁 </span>
              <span v-if="item.pac === 1" style="color: red"> PAC </span>
              <span>{{ item.matchUrl.length }}场比赛</span>
            </template>
            <a-avatar
              slot="avatar"
              :size="48"
              shape="square"
              :src="item.user_info.avatar_url"
            />
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </a-card>
    <a-modal v-model="showModal" title="比赛记录" :footer="null">
      <a-list :data-source="matchLogs">
        <a-list-item slot="renderItem" slot-scope="match, index">
          <a-list-item-meta>
            <a
              slot="title"
              :href="'https://arena.5eplay.com/data/match/' + match.code"
              target="_blank"
              >{{ match.code }}</a
            >
          </a-list-item-meta>
          <div>
            <a-badge
              :count="result(match.result, 1)"
              :number-style="{
                backgroundColor: result(match.result, 0),
              }"
            />
          </div>
        </a-list-item>
      </a-list>
    </a-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      matchLogs: [],
    };
  },
  methods: {
    formatUnix(unix) {
      return dayjs.unix(unix).format("YYYY-MM-DD");
    },
    result(re, type) {
      if (type === 1) {
        switch (re) {
          case "WIN":
            return "胜";
          case "TIE":
            return "平";
          case "LOSE":
            return "负";
          default:
            break;
        }
      } else {
        switch (re) {
          case "WIN":
            return "green";
          case "TIE":
            return "orange";
          case "LOSE":
            return "red";
          default:
            break;
        }
      }
    },
    handle(url) {
      window.open(url, "_blank");
    },
    matchLog(list) {
      console.log(list);
      this.matchLogs = list;
      this.showModal = true;
    },
  },
};
</script>