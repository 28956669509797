<!-- 
	This is the user profile page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div style="padding: 24px">
    <!-- Header Background Image -->
    <!-- / Header Background Image -->

    <!-- User Profile Card -->

    <!-- User Profile Card -->
    <!-- <a-input placeholder="Name"> </a-input>
    <a-button type="primary" block html-type="submit" class="login-form-button">
      搜索
    </a-button> -->
    <a-row type="flex" :gutter="24" v-show="true">
      <!-- Platform Settings Column -->
      <a-col :span="24" :md="8" class="mb-16">
        <a-card
          :bordered="false"
          class="card-profile-head"
          style="margin: 0px 0px 24px"
          :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }"
        >
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12">
                <h6>搜索</h6>
                <!-- <p>done this month <span class="text-primary">+40%</span></p> -->
              </a-col>
              <a-col
                :span="24"
                :md="12"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <a-select default-value="2023" @change="changeYear">
                  <a-select-option
                    :value="item"
                    v-for="item in [2023, 2022, 2021, 2020, 2019]"
                    :key="item"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </template>
          <a-tooltip>
            <template slot="title">
              例:https://arena.5eplay.com/data/player/rehun</template
            >
            <a-spin :spinning="spin">
              <a-input-search
                placeholder="在这里输入5E战绩页地址."
                @search="getUser"
              />
            </a-spin>
          </a-tooltip>
        </a-card>
        <a-card
          :bordered="false"
          class="card-profile-head"
          style="margin: 0px 0px 24px"
          :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }"
        >
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12" class="col-info">
                <a-avatar
                  :size="74"
                  shape="square"
                  :src="userInfo.avatar_url"
                />
                <div class="avatar-info">
                  <h4 class="font-semibold m-0">{{ userInfo.username }}</h4>
                  <p v-if="userInfo.account_banned === ''" style="color: green">
                    未被封禁
                  </p>
                  <p
                    v-else-if="
                      userInfo.account_banned === '作弊封禁' ||
                      userInfo.account_banned === '作弊关联封禁'
                    "
                    style="color: red"
                  >
                    {{ userInfo.account_banned }}
                  </p>
                  <p v-else style="color: orange">
                    {{ userInfo.account_banned }}
                  </p>
                  <p v-if="pac === 1" style="color: red">PAC</p>
                </div>
              </a-col>
              <a-col
                :span="24"
                :md="12"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
            ,"
              >
                <!-- <a-radio-group size="small">
                  <a-radio-button value="overview">OVERVIEW</a-radio-button>
                  <a-radio-button value="teams">TEAMS</a-radio-button>
                  <a-radio-button value="projects">PROJECTS</a-radio-button>
                </a-radio-group> -->
              </a-col>
            </a-row>
          </template>
        </a-card>
        <a-card
          :bordered="false"
          class="card-profile-head"
          style="margin: 0px 0px 24px"
          :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }"
        >
          <template #title>
            <h6 class="font-semibold m-0">当前进度:{{ now_step }}</h6>
          </template>
        </a-card>
        <a-spin :spinning="spin">
          <!-- <CardBadUser :data="badUserList"></CardBadUser> -->
          <CardConversations
            :data="badUserList"
            :title="year === 2019 ? '作弊队友' : '作弊开黑队友'"
            :subTitle="badUserList.length"
          ></CardConversations>
        </a-spin>
        <a-spin :spinning="spin">
          <!-- <CardLuBadUser :data="lBadUserList"></CardLuBadUser> -->
          <CardConversations
            :data="lBadUserList"
            :title="year === 2019 ? '对面作弊的' : '作弊路人'"
            :subTitle="lBadUserList.length"
          ></CardConversations>
        </a-spin>
      </a-col>
      <!-- / Platform Settings Column -->

      <!-- Conversations Column -->
      <a-col :span="24" :md="16" class="mb-24">
        <a-spin :spinning="spin">
          <!-- Conversations Card -->
          <CardConversations
            :data="partyList"
            :title="year === 2019 ? '队友' : '开黑队友'"
            :subTitle="partyList.length"
          >
          </CardConversations>
        </a-spin>
        <!-- / Conversations Card -->
      </a-col>
      <!-- / Conversations Column -->
    </a-row>
  </div>
</template>

<script>
import CardConversations from "../components/Cards/CardConversations";
import config from "../config";
import { notification } from "ant-design-vue";
// import { Modal } from "ant-design-vue";
// Conversation's list data.
export default {
  components: {
    CardConversations,
  },
  data() {
    return {
      steamid_64: null,
      pac: 0,
      year: null,
      profileHeaderBtns: "overview",
      apiUrl: config.apiUrl,
      userInfo: {},
      matchList: [],
      now_step: "",
      party: {},
      partyObj: {}, // 开黑队友
      badUserObj: {}, // 开黑作弊
      lBadUserObj: {}, // 路人作弊的
      partyList: [], // 开黑队友
      badUserList: [], // 开黑作弊
      lBadUserList: [], // 路人作弊的
      spin: false,
    };
  },
  created() {
    this.warning();
  },
  methods: {
    warning() {
      const h = this.$createElement;
      console.clear();
      console.log("%crenazu99@outlook.com", "font-weight:bold;font-size:5em;");
      this.$success({
        title: "修复",
        // https://5e-1251138787.cos.ap-nanjing.myqcloud.com/%E6%9D%A5%E8%87%AAwqSWqVITAbHE%E5%9C%A8%E7%82%99%E7%83%AD%E6%B2%99%E5%9F%8E2%E7%9A%84%E7%B2%BE%E5%BD%A95%E6%9D%80%E9%AB%98%E5%85%89%E6%97%B6%E5%88%BB.mp4
        // content: h('div',{
        //   domProps:{
        //     innerHtml: "<span>由于5e平台限制了接口的访问速率,5eplus.net的功能暂时无法使用</span><br/>",
        //   }
        // })

        // <video width="320" height="240" controls>
        //   <source src="movie.mp4" type="video/mp4">
        //   <source src="movie.ogg" type="video/ogg">
        //   Your browser does not support the video tag.
        // </video>
        content: h("div", {}, [
          h("span", "已修复因证书问题产生的网络错误"),
          h("hr"),
          h(
            "span",
            "注意:2019年的比赛记录5E未标记队友是否是路人队友或开黑队友,"
          ),
          h("br", ""),
          h(
            "span",
            "故2019年的查询是'作弊队友',包括路人队友和开黑队友,作弊路人是指比赛的对立方作弊玩家"
          ),
          h("hr"),
          h("span", "有任何问题或交流或吹水或逮捕可进Q群 793989664 "),
          h("br", ""),
          h("span", {}, [
            "也可在B站",
            h(
              "a",
              {
                attrs: {
                  href: "https://www.bilibili.com/read/cv12663685",
                  target: "_blank",
                },
              },
              "cv12663685"
            ),"文章下留言"
          ]),
          // h("span", "希望5E官方可以也开发类似"),
          // h(
          //   "a",
          //   {
          //     attrs: {
          //       href: "https://developers.faceit.com/",
          //       target: "_blank",
          //     },
          //   },
          //   "Faceit dev"
          // ),
          // h("span", " 的开发者接口,查询平台玩家数据"),
          // h("p", "有任何问题或交流可联系 watanabeqwq@pm.me "),
        ]),
      });
    },
    async getUser(targetUrl) {
      this.$notification.destroy();
      this.steamid_64 = null;
      this.pac = 0;
      this.spin = true;
      this.party = {};
      this.userInfo = [];
      this.matchList = [];
      this.userInfo = {};
      this.matchList = [];
      this.party = {};
      this.partyObj = {}; // 开黑队友
      this.badUserObj = {}; // 开黑作弊
      this.lBadUserObj = {}; // 路人作弊的
      this.partyList = []; // 开黑队友
      this.badUserList = []; // 开黑作弊
      this.lBadUserList = []; // 路人作弊的
      this.now_step = "正在查询用户信息";
      targetUrl = targetUrl.replace("//data", "/data");
      var url = new URL(targetUrl);
      var urlName = url.pathname.split("/")[3].split("?")[0];
      try {
        var userInfo = await this.$http.get(this.apiUrl + "user/" + urlName);

        if (userInfo.data.status === false) {
          console.log("不存在该用户");
        } else {
          userInfo = userInfo.data.data;
          this.userInfo = userInfo;
          console.log(userInfo);
          this.getPartyUser(urlName);
        }
      } catch (error) {
        this.$notification["error"]({
          message: "发生错误",
          description: "大概是服务器网络原因导致的错误",
        });
      }

      //
      // if (userInfo.status === "未被封禁") {
      //   var matchObj = {
      //     9: "优先组排",
      //     1: "天梯模式",
      //     8: "优先单排",
      //   };

      //   var getPart = async (matchType, year, season) => {
      //     var playerName = userInfo.name;
      //     var url = "https://arena.5eplay.com/api/data/player/" + playerName;
      //     var LRdata = await this.$http.get(
      //       `${url}?season=${season}&yyyy=${year}&match_type=${matchType}`
      //     );
      //     var matchTotal = LRdata.data.match_total;
      //     if (matchTotal && parseInt(matchTotal) !== 0) {
      //       this.$http.get.get(
      //         `${url}/weapons?season=${season}&yyyy=${year}&match_type=${matchType}`,
      //         (data) => {
      //           var parts = data.data.part;
      //           var arrSum = 0;
      //           for (var i = 0; i < Object.values(parts).length; i++) {
      //             arrSum += Object.values(parts)[i];
      //           }
      //           console.log(
      //             `当前赛季${matchObj[matchType]} 左右臂比率:` +
      //               parseInt((parts.part_left_arm / arrSum) * 100) +
      //               "%:" +
      //               parseInt((parts.part_right_arm / arrSum) * 100) +
      //               "% 总局数:" +
      //               matchTotal
      //           );
      //         }
      //       );
      //     }
      //   };
      //   for (var key in Object.keys(matchObj)) {
      //     getPart(Object.keys(matchObj)[key], 2022, 4);
      //   }
      // }
    },
    changeYear(year) {
      this.year = year;
      if (year === 2019) {
        const h = this.$createElement;
        this.$info({
          title: "注意:",
          content: h("div", {}, [
            h("span", "2019年5e平台未标记是否是开黑比赛"),
            h("br"),
            h(
              "span",
              "所以2019年的统计开黑队友为所有比赛的队友,作弊路人为作弊的对面路人"
            ),
          ]),
        });
      }
    },
    async getPartyUser(urlName) {
      // 先遍历match
      // /user/matchList/:userUrlName
      var matchList = [];
      var i = 0;
      var that = this;
      const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
      };
      var first_step = 1;
      var end_step = 0;
      while (true) {
        await sleep(300);
        i = i + 1;
        let matchs;
        if (this.year) {
          matchs = await this.$http.get(
            this.apiUrl +
              "user/matchList/" +
              urlName +
              "?page=" +
              i +
              "&year=" +
              this.year
          );
        } else {
          matchs = await this.$http.get(
            this.apiUrl + "user/matchList/" + urlName + "?page=" + i
          );
        }

        matchs = matchs.data;
        let matchsList = matchs.data;
        console.log(matchsList);
        if (matchsList) {
          end_step = end_step + matchsList.length;
          this.now_step = "正在查询第" + first_step + "到" + end_step + "场";
          first_step = end_step + 1;
        }

        if (matchs.status === false) {
          break;
        } else {
          for (let x = 0; x < matchsList.length; x = x + 1) {
            matchList.push(matchsList[x]);
            // that.now_step = matchsList[x].match_code;
            this.checkPartyUser(matchsList[x].match_code, urlName);
          }
        }
      }
      this.matchList = matchList;
      this.now_step = "已结束";

      await this.coumputeUser(this.party, this.lBadUser);
      // await this.checkPac();
    },

    async checkPartyUser(match_code, userUrlName) {
      const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
      };
      await sleep(100);
      var gameUser = await this.$http.get(
        this.apiUrl + "match/" + match_code + "/" + userUrlName
      );
      this.steamid_64 = gameUser.data.steamID;
      var badUsers = gameUser.data.badUser; //开黑作弊队友
      var badUserRs = gameUser.data.badUserR; // 作弊路人
      var partyUsers = gameUser.data.party; //开黑队友
      var match = gameUser.data.match;
      for (let badUser in badUsers) {
        // 先查找,如果有的话+1,没有的话就push?
        // this.badUserList.push(badUsers[badUser]);
        if (this.badUserObj.hasOwnProperty(badUser)) {
          this.badUserObj[badUser].num = this.badUserObj[badUser].num + 1;
        } else {
          this.badUserObj[badUser] = badUsers[badUser];
          this.badUserObj[badUser].num = 1;
          this.badUserObj[badUser].matchUrl = [];
        }
        this.badUserObj[badUser].matchUrl.push(match);
      }
      // [{张:{num:1}}]
      for (let partyUser in partyUsers) {
        // this.partyList.push(partyUsers[partyUser]);
        if (this.partyObj.hasOwnProperty(partyUser)) {
          this.partyObj[partyUser].num = this.partyObj[partyUser].num + 1;
        } else {
          this.partyObj[partyUser] = partyUsers[partyUser];
          this.partyObj[partyUser].num = 1;
          this.partyObj[partyUser].matchUrl = [];
        }
        this.partyObj[partyUser].matchUrl.push(match);
      }
      for (let badUserR in badUserRs) {
        // this.lBadUserList.push(badUserRs[badUserR]);

        if (this.lBadUserObj.hasOwnProperty(badUserR)) {
          this.lBadUserObj[badUserR].num = this.lBadUserObj[badUserR].num + 1;
        } else {
          this.lBadUserObj[badUserR] = badUserRs[badUserR];
          this.lBadUserObj[badUserR].num = 1;
          this.lBadUserObj[badUserR].matchUrl = [];
        }
        this.lBadUserObj[badUserR].matchUrl.push(match);
      }
    },
    sortUser(array) {
      var arr = array;
      // 遍历数组
      for (var i = 0; i < arr.length - 1; i++) {
        for (var j = 0; j < arr.length - 1 - i; j++) {
          if (arr[j].matchUrl.length < arr[j + 1].matchUrl.length) {
            var num = arr[j];
            arr[j] = arr[j + 1];
            arr[j + 1] = num;
          }
        }
      }
      return arr;
    },

    async coumputeUser(obj, bobj) {
      // this.coumputeUser(this.party, this.lBadUser);
      const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
      };
      await sleep(2000);
      var partyUserNum = 0;
      var banUserNum = 0;
      var badLUserNum = 0;
      var partyObj = obj;
      partyUserNum = Object.keys(this.partyObj).length;
      banUserNum = Object.keys(this.badUserObj).length;
      badLUserNum = Object.keys(this.lBadUserObj).length;
      var that = this;
      for (let user in this.partyObj) {
        that.partyObj[user].pac = 0;
        that.partyList.push(that.partyObj[user]);
      }
      for (let user in this.badUserObj) {
        that.badUserObj[user].pac = 0;
        that.badUserList.push(that.badUserObj[user]);
      }
      for (let user in this.lBadUserObj) {
        that.lBadUserObj[user].pac = 0;
        that.lBadUserList.push(that.lBadUserObj[user]);
      }
      // for (const lurenBadUser in bobj) {
      //   badLUserNum = badLUserNum + 1;
      //   this.lBadUserList.push(bobj[lurenBadUser]);
      // }
      // badLUserNum = this.lBadUserList.length;
      this.partyList = this.sortUser(this.partyList);
      this.badUserList = this.sortUser(this.badUserList);
      this.lBadUserList = this.sortUser(this.lBadUserList);
      this.now_step = `作弊开黑队友数:开黑总队友数=${banUserNum}:${partyUserNum}`;
      this.spin = false;
    },
    async checkPac() {
      this.$notification["info"]({
        message: "正在查询PAC状态",
        description: "正在查询完美平台PAC状态....可能需要一点时间",
        duration: 0,
      });
      let pacstatus = await this.pacStatus(this.steamid_64);
      if (pacstatus === true) {
        this.pac = 1;
      } else {
        this.pac = 0;
      }
      for (let i = 0; i < this.partyList.length; i++) {
        let user_steam = this.partyList[i].user_info.steamid_64;
        let pacstatus = await this.pacStatus(user_steam);
        if (pacstatus === true) {
          this.partyList[i].pac = 1;
        } else {
          this.partyList[i].pac = 0;
        }
        // that.partyList.push(that.partyObj[user]);
      }
      for (let i = 0; i < this.badUserList.length; i++) {
        let user_steam = this.badUserList[i].user_info.steamid_64;
        let pacstatus = await this.pacStatus(user_steam);
        if (pacstatus === true) {
          this.badUserList[i].pac = 1;
        } else {
          this.badUserList[i].pac = 0;
        }
      }
      for (let i = 0; i < this.lBadUserList.length; i++) {
        let user_steam = this.lBadUserList[i].user_info.steamid_64;
        let pacstatus = await this.pacStatus(user_steam);
        if (pacstatus === true) {
          this.lBadUserList[i].pac = 1;
        } else {
          this.lBadUserList[i].pac = 0;
        }
      }
      this.$notification.destroy();
      this.$notification["success"]({
        message: "PAC状态查询完成",
        description: "被PAC用户会在比赛场数前显示PAC",
        duration: 0,
      });
      console.log(this.badUserList);
      console.log(this.lBadUserList);
      console.log(this.partyList);
    },
    async pacStatus(steamid) {
      var apiUrl = this.apiUrl + "user/pac/" + steamid;
      let status = await this.$http.get(apiUrl);
      if (status.data.pac === 1) {
        return true;
      }
      return false;
    },
    go(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss">
</style>