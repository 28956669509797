var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',{staticClass:"header-solid h-full",staticStyle:{"margin":"0px 0px 24px"},attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('h6',{staticClass:"font-semibold m-0"},[_vm._v(_vm._s(_vm.title))]),(_vm.subTitle)?_c('p',[_vm._v(" 共: "),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.subTitle)+"人")])]):_vm._e()])],1)]},proxy:true}])},[_c('a-list',{staticClass:"conversations-list",attrs:{"item-layout":"horizontal","split":false,"data-source":_vm.data},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{},[_c('a-button',{attrs:{"slot":"actions","type":"link"},on:{"click":function($event){return _vm.matchLog(item.matchUrl)}},slot:"actions"},[_vm._v(" 比赛记录 ")]),_c('a-button',{attrs:{"slot":"actions","type":"link"},on:{"click":function($event){return _vm.handle(
              'https://arena.5eplay.com/data/player/' + item.user_info.domain
            )}},slot:"actions"},[_vm._v(" 战绩页 ")]),_c('a-list-item-meta',{attrs:{"title":item.userName},scopedSlots:_vm._u([{key:"description",fn:function(){return [(
                item.user_info.account_status === -4
              )?_c('span',{staticStyle:{"color":"red"}},[_vm._v("作弊封禁")]):(
                item.user_info.account_status === -6
              )?_c('span',{staticStyle:{"color":"orange"}},[_vm._v("违规行为封禁")]):_c('span',[_vm._v("未被封禁 ")]),(item.pac === 1)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" PAC ")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.matchUrl.length)+"场比赛")])]},proxy:true}],null,true)},[_c('a-avatar',{attrs:{"slot":"avatar","size":48,"shape":"square","src":item.user_info.avatar_url},slot:"avatar"})],1)],1)}}])})],1),_c('a-modal',{attrs:{"title":"比赛记录","footer":null},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('a-list',{attrs:{"data-source":_vm.matchLogs},scopedSlots:_vm._u([{key:"renderItem",fn:function(match, index){return _c('a-list-item',{},[_c('a-list-item-meta',[_c('a',{attrs:{"slot":"title","href":'https://arena.5eplay.com/data/match/' + match.code,"target":"_blank"},slot:"title"},[_vm._v(_vm._s(match.code))])]),_c('div',[_c('a-badge',{attrs:{"count":_vm.result(match.result, 1),"number-style":{
              backgroundColor: _vm.result(match.result, 0),
            }}})],1)],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }